.at-header1 {
  font-size: var(--header1); // 32px
}

.at-header2 {
  font-size: var(--header2); // 24px
}

.at-header3 {
  font-size: var(--header3); // 20px
}

.at-header4 {
  font-size: var(--header4); // 16px
}

.at-header5 {
  font-size: var(--header5); // 14px
}

.at-header6 {
  font-size: var(--header6); // 12px
}

.at-paragraph-regular {
  font-size: var(--paragraph-regular); // 16px
}

.at-paragraph-small {
  font-size: var(--paragraph-small); // 14px
}

.at-paragraph-extra-small {
  font-size: var(--paragraph-extra-small); // 12px
}

.at-paragraph-mega-small {
  font-size: var(--paragraph-mega-small); // 10px
}
