@media screen and (min-width: 576px) {
  .at-header1-sm {
    font-size: var(--header1); // 32px
  }

  .at-header2-sm {
    font-size: var(--header2); // 24px
  }

  .at-header3-sm {
    font-size: var(--header3); // 20px
  }

  .at-header4-sm {
    font-size: var(--header4); // 16px
  }

  .at-header5-sm {
    font-size: var(--header5); // 14px
  }

  .at-header6-sm {
    font-size: var(--header6); // 12px
  }

  .at-paragraph-sm-regular {
    font-size: var(--paragraph-regular); // 16px
  }

  .at-paragraph-sm-small {
    font-size: var(--paragraph-small); // 14px
  }

  .at-paragraph-sm-extra-small {
    font-size: var(--paragraph-extra-small); // 12px
  }

  .at-paragraph-sm-mega-small {
    font-size: var(--paragraph-mega-small); // 10px
  }
}

@media screen and (min-width: 768px) {
  .at-header1-md {
    font-size: var(--header1); // 32px
  }

  .at-header2-md {
    font-size: var(--header2); // 24px
  }

  .at-header3-md {
    font-size: var(--header3); // 20px
  }

  .at-header4-md {
    font-size: var(--header4); // 16px
  }

  .at-header5-md {
    font-size: var(--header5); // 14px
  }

  .at-header6-md {
    font-size: var(--header6); // 12px
  }

  .at-paragraph-md-regular {
    font-size: var(--paragraph-regular); // 16px
  }

  .at-paragraph-md-small {
    font-size: var(--paragraph-small); // 14px
  }

  .at-paragraph-md-extra-small {
    font-size: var(--paragraph-extra-small); // 12px
  }

  .at-paragraph-md-mega-small {
    font-size: var(--paragraph-mega-small); // 10px
  }
}

@media screen and (min-width: 768px) {
  .at-header1-xl {
    font-size: var(--header1); // 32px
  }

  .at-header2-xl {
    font-size: var(--header2); // 24px
  }

  .at-header3-xl {
    font-size: var(--header3); // 20px
  }

  .at-header4-xl {
    font-size: var(--header4); // 16px
  }

  .at-header5-xl {
    font-size: var(--header5); // 14px
  }

  .at-header6-xl {
    font-size: var(--header6); // 12px
  }

  .at-paragraph-xl-regular {
    font-size: var(--paragraph-regular); // 16px
  }

  .at-paragraph-xl-small {
    font-size: var(--paragraph-small); // 14px
  }

  .at-paragraph-xl-extra-small {
    font-size: var(--paragraph-extra-small); // 12px
  }

  .at-paragraph-xl-mega-small {
    font-size: var(--paragraph-mega-small); // 10px
  }
}
