.at-shadow-xsmall {
  box-shadow: var(--shadow-xsmall);
}

.at-shadow-small {
  box-shadow: var(--shadow-small);
}

.at-shadow-medium {
  box-shadow: var(--shadow-medium);
}

.at-shadow-large {
  box-shadow: var(--shadow-large);
}

.at-shadow-xlarge {
  box-shadow: var(--shadow-xlarge);
}

.at-shadow-mega-large {
  box-shadow: var(--shadow-mega-large);
}

.at-shadow-auth-card {
  box-shadow: var(--shadow-auth-card);
}
