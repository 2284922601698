.at-border {
  border: 1px solid transparent;
}

.at-border-4 {
  border: 4px solid transparent;
}

.at-border-top {
  border-block-start-width: 1px;
  border-block-start-style: solid;
}

.at-border-bottom {
  border-block-end-width: 1px;
  border-block-end-style: solid;
}

.at-border-start {
  border-inline-start: 1px solid transparent;
}

.at-border-end {
  border-inline-end: 1px solid transparent;
}

.at-border-bottom-2 {
  border-block-end: 2px solid transparent;
}

.at-border-unset {
  border: unset;
}

.at-border-none {
  border: 0 !important;
}
