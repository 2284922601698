.at-bg-primary1 {
  background: var(--primary1);
}

.at-bg-primary2 {
  background: var(--primary2);
}

.at-bg-secondary1 {
  background: var(--secondary1);
}

.at-bg-secondary2 {
  background: var(--secondary2);
}

.at-bg-secondary3 {
  background: var(--secondary3);
}

.at-bg-secondary4 {
  background: var(--secondary4);
}

.at-bg-secondary5 {
  background: var(--secondary5);
}

.at-bg-secondary6 {
  background: var(--secondary6);
}

.at-bg-secondary1-p2 {
  background: var(--secondary1-p2);
}

.at-bg-success {
  background: var(--success);
}

.at-bg-failure {
  background: var(--failure);
}

.at-bg-warning {
  background: var(--warning);
}

.at-bg-information {
  background: var(--information);
}

.at-bg-status-pending {
  background: var(--status-pending);
}

.at-bg-status-declined {
  background: var(--status-declined);
}

.at-bg-status-accepted {
  background: var(--status-accepted);
}

.at-bg-status-delayed {
  background: var(--status-delayed);
}

.at-bg-status-shipped {
  background: var(--status-shipped);
}

.at-bg-status-delivered {
  background: var(--status-delivered);
}

.at-bg-status-rejected {
  background: var(--status-rejected);
}

.at-bg-success2 {
  background: var(--success2);
}

.at-bg-failure2 {
  background: var(--failure2);
}

.at-bg-warning2 {
  background: var(-warning2);
}

.at-bg-information2 {
  background: var(--information2);
}

.at-bg-white {
  background: #ffffff;
}

.at-bg-transparent {
  background: transparent;
}
