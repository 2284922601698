@media screen and (min-width: 768px) {
  .at-m-md-1 {
    margin: 0.25rem; //4px
  }

  .at-m-md-2 {
    margin: 0.5rem; //8px
  }

  .at-m-md-3 {
    margin: 0.75rem; //12px
  }

  .at-m-md-4 {
    margin: 1rem; //16px
  }

  .at-m-md-5 {
    margin: 1.25rem; //20px
  }

  .at-m-md-6 {
    margin: 1.5rem; //24px
  }

  .at-m-md-8 {
    margin: 2rem; //32px
  }

  .at-m-md-10 {
    margin: 3rem; //48px
  }

  .at-mx-md-1 {
    margin-inline: 0.25rem; //4px
  }

  .at-mx-md-2 {
    margin-inline: 0.5rem; //8px
  }

  .at-mx-md-3 {
    margin-inline: 0.75rem; //12px
  }

  .at-mx-md-4 {
    margin-inline: 1rem; //16px
  }

  .at-mx-md-5 {
    margin-inline: 1.25rem; //20px
  }

  .at-mx-md-6 {
    margin-inline: 1.5rem; //24px
  }

  .at-mx-md-8 {
    margin-inline: 2rem; //32px
  }

  .at-mx-md-10 {
    margin-inline: 3rem; //48px
  }

  .at-my-md-1 {
    margin-block: 0.25rem; //4px
  }

  .at-my-md-2 {
    margin-block: 0.5rem; //8px
  }

  .at-my-md-3 {
    margin-block: 0.75rem; //12px
  }

  .at-my-md-4 {
    margin-block: 1rem; //16px
  }

  .at-my-md-5 {
    margin-block: 1.25rem; //20px
  }

  .at-my-md-6 {
    margin-block: 1.5rem; //24px
  }

  .at-my-md-8 {
    margin-block: 2rem; //32px
  }

  .at-my-md-10 {
    margin-block: 3rem; //48px
  }

  .at-ms-md-1 {
    margin-inline-start: 0.25rem; //4px
  }

  .at-ms-md-2 {
    margin-inline-start: 0.5rem; //8px
  }

  .at-ms-md-3 {
    margin-inline-start: 0.75rem; //12px
  }

  .at-ms-md-4 {
    margin-inline-start: 1rem; //16px
  }

  .at-ms-md-5 {
    margin-inline-start: 1.25rem; //20px
  }

  .at-ms-md-6 {
    margin-inline-start: 1.5rem; //24px
  }

  .at-ms-md-7 {
    margin-inline-start: 1.75rem; //28px
  }

  .at-ms-md-8 {
    margin-inline-start: 2rem; //32px
  }

  .at-ms-md-9 {
    margin-inline-start: 2.5rem; //40px
  }

  .at-ms-md-10 {
    margin-inline-start: 3rem; //48px
  }

  .at-me-md-1 {
    margin-inline-end: 0.25rem; //4px
  }

  .at-me-md-2 {
    margin-inline-end: 0.5rem; //8px
  }

  .at-me-md-3 {
    margin-inline-end: 0.75rem; //12px
  }

  .at-me-md-4 {
    margin-inline-end: 1rem; //16px
  }

  .at-me-md-5 {
    margin-inline-end: 1.25rem; //20px
  }

  .at-me-md-6 {
    margin-inline-end: 1.5rem; //24px
  }

  .at-me-md-8 {
    margin-inline-end: 2rem; //32px
  }

  .at-me-md-10 {
    margin-inline-end: 3rem; //48px
  }

  .at-mt-md-1 {
    margin-block-start: 0.25rem; //4px
  }

  .at-mt-md-2 {
    margin-block-start: 0.5rem; //8px
  }

  .at-mt-md-3 {
    margin-block-start: 0.75rem; //12px
  }

  .at-mt-md-4 {
    margin-block-start: 1rem; //16px
  }

  .at-mt-md-5 {
    margin-block-start: 1.25rem; //20px
  }

  .at-mt-md-6 {
    margin-block-start: 1.5rem; //24px
  }

  .at-mt-md-8 {
    margin-block-start: 2rem; //32px
  }

  .at-mt-md-10 {
    margin-block-start: 3rem; //48px
  }

  .at-mb-md-1 {
    margin-block-end: 0.25rem; //4px
  }

  .at-mb-md-2 {
    margin-block-end: 0.5rem; //8px
  }

  .at-mb-md-3 {
    margin-block-end: 0.75rem; //12px
  }

  .at-mb-md-4 {
    margin-block-end: 1rem; //16px
  }

  .at-mb-md-5 {
    margin-block-end: 1.25rem; //20px
  }

  .at-mb-md-6 {
    margin-block-end: 1.5rem; //24px
  }

  .at-mb-md-8 {
    margin-block-end: 2rem; //32px
  }

  .at-mb-md-9 {
    margin-block-end: 2.5rem; //40px
  }

  .at-mb-md-10 {
    margin-block-end: 3rem; //48px
  }

  .at-mb-md-0 {
    margin-block-end: 0rem; //4px
  }
}

@media screen and (min-width: 1200px) {
  .at-m-xl-1 {
    margin: 0.25rem; //4px
  }

  .at-m-xl-2 {
    margin: 0.5rem; //8px
  }

  .at-m-xl-3 {
    margin: 0.75rem; //12px
  }

  .at-m-xl-4 {
    margin: 1rem; //16px
  }

  .at-m-xl-5 {
    margin: 1.25rem; //20px
  }

  .at-m-xl-6 {
    margin: 1.5rem; //24px
  }

  .at-m-xl-8 {
    margin: 2rem; //32px
  }

  .at-m-xl-10 {
    margin: 3rem; //48px
  }

  .at-mx-xl-1 {
    margin-inline: 0.25rem; //4px
  }

  .at-mx-xl-2 {
    margin-inline: 0.5rem; //8px
  }

  .at-mx-xl-3 {
    margin-inline: 0.75rem; //12px
  }

  .at-mx-xl-4 {
    margin-inline: 1rem; //16px
  }

  .at-mx-xl-5 {
    margin-inline: 1.25rem; //20px
  }

  .at-mx-xl-6 {
    margin-inline: 1.5rem; //24px
  }

  .at-mx-xl-8 {
    margin-inline: 2rem; //32px
  }

  .at-mx-xl-10 {
    margin-inline: 3rem; //48px
  }

  .at-my-xl-1 {
    margin-block: 0.25rem; //4px
  }

  .at-my-xl-2 {
    margin-block: 0.5rem; //8px
  }

  .at-my-xl-3 {
    margin-block: 0.75rem; //12px
  }

  .at-my-xl-4 {
    margin-block: 1rem; //16px
  }

  .at-my-xl-5 {
    margin-block: 1.25rem; //20px
  }

  .at-my-xl-6 {
    margin-block: 1.5rem; //24px
  }

  .at-my-xl-8 {
    margin-block: 2rem; //32px
  }

  .at-my-xl-10 {
    margin-block: 3rem; //48px
  }

  .at-ms-xl-1 {
    margin-inline-start: 0.25rem; //4px
  }

  .at-ms-xl-2 {
    margin-inline-start: 0.5rem; //8px
  }

  .at-ms-xl-3 {
    margin-inline-start: 0.75rem; //12px
  }

  .at-ms-xl-4 {
    margin-inline-start: 1rem; //16px
  }

  .at-ms-xl-5 {
    margin-inline-start: 1.25rem; //20px
  }

  .at-ms-xl-6 {
    margin-inline-start: 1.5rem; //24px
  }

  .at-ms-xl-7 {
    margin-inline-start: 1.75rem; //28px
  }

  .at-ms-xl-8 {
    margin-inline-start: 2rem; //32px
  }

  .at-ms-xl-9 {
    margin-inline-start: 2.5rem; //40px
  }

  .at-ms-xl-10 {
    margin-inline-start: 3rem; //48px
  }

  .at-me-xl-1 {
    margin-inline-end: 0.25rem; //4px
  }

  .at-me-xl-2 {
    margin-inline-end: 0.5rem; //8px
  }

  .at-me-xl-3 {
    margin-inline-end: 0.75rem; //12px
  }

  .at-me-xl-4 {
    margin-inline-end: 1rem; //16px
  }

  .at-me-xl-5 {
    margin-inline-end: 1.25rem; //20px
  }

  .at-me-xl-6 {
    margin-inline-end: 1.5rem; //24px
  }

  .at-me-xl-8 {
    margin-inline-end: 2rem; //32px
  }

  .at-me-xl-10 {
    margin-inline-end: 3rem; //48px
  }

  .at-mt-xl-1 {
    margin-block-start: 0.25rem; //4px
  }

  .at-mt-xl-2 {
    margin-block-start: 0.5rem; //8px
  }

  .at-mt-xl-3 {
    margin-block-start: 0.75rem; //12px
  }

  .at-mt-xl-4 {
    margin-block-start: 1rem; //16px
  }

  .at-mt-xl-5 {
    margin-block-start: 1.25rem; //20px
  }

  .at-mt-xl-6 {
    margin-block-start: 1.5rem; //24px
  }

  .at-mt-xl-8 {
    margin-block-start: 2rem; //32px
  }

  .at-mt-xl-10 {
    margin-block-start: 3rem; //48px
  }

  .at-mb-xl-1 {
    margin-block-end: 0.25rem; //4px
  }

  .at-mb-xl-2 {
    margin-block-end: 0.5rem; //8px
  }

  .at-mb-xl-3 {
    margin-block-end: 0.75rem; //12px
  }

  .at-mb-xl-4 {
    margin-block-end: 1rem; //16px
  }

  .at-mb-xl-5 {
    margin-block-end: 1.25rem; //20px
  }

  .at-mb-xl-6 {
    margin-block-end: 1.5rem; //24px
  }

  .at-mb-xl-8 {
    margin-block-end: 2rem; //32px
  }

  .at-mb-xl-9 {
    margin-block-end: 2.5rem; //40px
  }

  .at-mb-xl-10 {
    margin-block-end: 3rem; //48px
  }

  .at-mb-xl-0 {
    margin-block-end: 0rem; //4px
  }
}

@media screen and (min-width: 1400px) {
  .at-m-xxl-1 {
    margin: 0.25rem; //4px
  }

  .at-m-xxl-2 {
    margin: 0.5rem; //8px
  }

  .at-m-xxl-3 {
    margin: 0.75rem; //12px
  }

  .at-m-xxl-4 {
    margin: 1rem; //16px
  }

  .at-m-xxl-5 {
    margin: 1.25rem; //20px
  }

  .at-m-xxl-6 {
    margin: 1.5rem; //24px
  }

  .at-m-xxl-8 {
    margin: 2rem; //32px
  }

  .at-m-xxl-10 {
    margin: 3rem; //48px
  }

  .at-mx-xxl-1 {
    margin-inline: 0.25rem; //4px
  }

  .at-mx-xxl-2 {
    margin-inline: 0.5rem; //8px
  }

  .at-mx-xxl-3 {
    margin-inline: 0.75rem; //12px
  }

  .at-mx-xxl-4 {
    margin-inline: 1rem; //16px
  }

  .at-mx-xxl-5 {
    margin-inline: 1.25rem; //20px
  }

  .at-mx-xxl-6 {
    margin-inline: 1.5rem; //24px
  }

  .at-mx-xxl-8 {
    margin-inline: 2rem; //32px
  }

  .at-mx-xxl-10 {
    margin-inline: 3rem; //48px
  }

  .at-my-xxl-1 {
    margin-block: 0.25rem; //4px
  }

  .at-my-xxl-2 {
    margin-block: 0.5rem; //8px
  }

  .at-my-xxl-3 {
    margin-block: 0.75rem; //12px
  }

  .at-my-xxl-4 {
    margin-block: 1rem; //16px
  }

  .at-my-xxl-5 {
    margin-block: 1.25rem; //20px
  }

  .at-my-xxl-6 {
    margin-block: 1.5rem; //24px
  }

  .at-my-xxl-8 {
    margin-block: 2rem; //32px
  }

  .at-my-xxl-10 {
    margin-block: 3rem; //48px
  }

  .at-ms-xxl-1 {
    margin-inline-start: 0.25rem; //4px
  }

  .at-ms-xxl-2 {
    margin-inline-start: 0.5rem; //8px
  }

  .at-ms-xxl-3 {
    margin-inline-start: 0.75rem; //12px
  }

  .at-ms-xxl-4 {
    margin-inline-start: 1rem; //16px
  }

  .at-ms-xxl-5 {
    margin-inline-start: 1.25rem; //20px
  }

  .at-ms-xxl-6 {
    margin-inline-start: 1.5rem; //24px
  }

  .at-ms-xxl-7 {
    margin-inline-start: 1.75rem; //28px
  }

  .at-ms-xxl-8 {
    margin-inline-start: 2rem; //32px
  }

  .at-ms-xxl-9 {
    margin-inline-start: 2.5rem; //40px
  }

  .at-ms-xxl-10 {
    margin-inline-start: 3rem; //48px
  }

  .at-ms-xxl-0 {
    margin-inline-start: 0;
  }

  .at-me-xxl-1 {
    margin-inline-end: 0.25rem; //4px
  }

  .at-me-xxl-2 {
    margin-inline-end: 0.5rem; //8px
  }

  .at-me-xxl-3 {
    margin-inline-end: 0.75rem; //12px
  }

  .at-me-xxl-4 {
    margin-inline-end: 1rem; //16px
  }

  .at-me-xxl-5 {
    margin-inline-end: 1.25rem; //20px
  }

  .at-me-xxl-6 {
    margin-inline-end: 1.5rem; //24px
  }

  .at-me-xxl-8 {
    margin-inline-end: 2rem; //32px
  }

  .at-me-xxl-10 {
    margin-inline-end: 3rem; //48px
  }

  .at-mt-xxl-1 {
    margin-block-start: 0.25rem; //4px
  }

  .at-mt-xxl-2 {
    margin-block-start: 0.5rem; //8px
  }

  .at-mt-xxl-3 {
    margin-block-start: 0.75rem; //12px
  }

  .at-mt-xxl-4 {
    margin-block-start: 1rem; //16px
  }

  .at-mt-xxl-5 {
    margin-block-start: 1.25rem; //20px
  }

  .at-mt-xxl-6 {
    margin-block-start: 1.5rem; //24px
  }

  .at-mt-xxl-8 {
    margin-block-start: 2rem; //32px
  }

  .at-mt-xxl-10 {
    margin-block-start: 3rem; //48px
  }

  .at-mb-xxl-1 {
    margin-block-end: 0.25rem; //4px
  }

  .at-mb-xxl-2 {
    margin-block-end: 0.5rem; //8px
  }

  .at-mb-xxl-3 {
    margin-block-end: 0.75rem; //12px
  }

  .at-mb-xxl-4 {
    margin-block-end: 1rem; //16px
  }

  .at-mb-xxl-5 {
    margin-block-end: 1.25rem; //20px
  }

  .at-mb-xxl-6 {
    margin-block-end: 1.5rem; //24px
  }

  .at-mb-xxl-8 {
    margin-block-end: 2rem; //32px
  }

  .at-mb-xxl-9 {
    margin-block-end: 2.5rem; //40px
  }

  .at-mb-xxl-10 {
    margin-block-end: 3rem; //48px
  }

  .at-mb-xxl-0 {
    margin-block-end: 0rem; //4px
  }
}
