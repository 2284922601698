:root {
  --shadow-xsmall: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  --shadow-small: 0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 1px 2px -1px rgba(16, 24, 40, 0.1);
  --shadow-medium: 0 4px 6px -1px rgba(16, 24, 40, 0.1),
    0 2px 4px -2px rgba(16, 24, 40, 0.1);
  --shadow-large: 0 10px 15px -3px rgba(16, 24, 40, 0.1),
    0 4px 6px -4px rgba(16, 24, 40, 0.1);
  --shadow-xlarge: 0 20px 25px -5px rgba(16, 24, 40, 0.1),
    0 8px 10px -6px rgba(16, 24, 40, 0.1);
  --shadow-mega-large: 0 25px 40px -12px rgba(16, 24, 40, 0.25);
  --shadow-auth-card: 0 9px 24px 0 rgba(0, 0, 0, 0.18);
}
