.at-m-1 {
  margin: 0.25rem; //4px
}

.at-m-2 {
  margin: 0.5rem; //8px
}

.at-m-3 {
  margin: 0.75rem; //12px
}

.at-m-4 {
  margin: 1rem; //16px
}

.at-m-5 {
  margin: 1.25rem; //20px
}

.at-m-6 {
  margin: 1.5rem; //24px
}

.at-m-8 {
  margin: 2rem; //32px
}

.at-m-10 {
  margin: 3rem; //48px
}

.at-mx-1 {
  margin-inline: 0.25rem; //4px
}

.at-mx-2 {
  margin-inline: 0.5rem; //8px
}

.at-mx-3 {
  margin-inline: 0.75rem; //12px
}

.at-mx-4 {
  margin-inline: 1rem; //16px
}

.at-mx-5 {
  margin-inline: 1.25rem; //20px
}

.at-mx-6 {
  margin-inline: 1.5rem; //24px
}

.at-mx-8 {
  margin-inline: 2rem; //32px
}

.at-mx-10 {
  margin-inline: 3rem; //48px
}

.at-my-1 {
  margin-block: 0.25rem; //4px
}

.at-my-2 {
  margin-block: 0.5rem; //8px
}

.at-my-3 {
  margin-block: 0.75rem; //12px
}

.at-my-4 {
  margin-block: 1rem; //16px
}

.at-my-5 {
  margin-block: 1.25rem; //20px
}

.at-my-6 {
  margin-block: 1.5rem; //24px
}

.at-my-8 {
  margin-block: 2rem; //32px
}

.at-my-10 {
  margin-block: 3rem; //48px
}

.at-ms-1 {
  margin-inline-start: 0.25rem; //4px
}

.at-ms-2 {
  margin-inline-start: 0.5rem; //8px
}

.at-ms-3 {
  margin-inline-start: 0.75rem; //12px
}

.at-ms-4 {
  margin-inline-start: 1rem; //16px
}

.at-ms-5 {
  margin-inline-start: 1.25rem; //20px
}

.at-ms-6 {
  margin-inline-start: 1.5rem; //24px
}

.at-ms-7 {
  margin-inline-start: 1.75rem; //28px
}

.at-ms-8 {
  margin-inline-start: 2rem; //32px
}

.at-ms-9 {
  margin-inline-start: 2.5rem; //40px
}

.at-ms-10 {
  margin-inline-start: 3rem; //48px
}

.at-me-1 {
  margin-inline-end: 0.25rem; //4px
}

.at-me-2 {
  margin-inline-end: 0.5rem; //8px
}

.at-me-3 {
  margin-inline-end: 0.75rem; //12px
}

.at-me-4 {
  margin-inline-end: 1rem; //16px
}

.at-me-5 {
  margin-inline-end: 1.25rem; //20px
}

.at-me-6 {
  margin-inline-end: 1.5rem; //24px
}

.at-me-8 {
  margin-inline-end: 2rem; //32px
}

.at-me-10 {
  margin-inline-end: 3rem; //48px
}

.at-mt-1 {
  margin-block-start: 0.25rem; //4px
}

.at-mt-2 {
  margin-block-start: 0.5rem; //8px
}

.at-mt-3 {
  margin-block-start: 0.75rem; //12px
}

.at-mt-4 {
  margin-block-start: 1rem; //16px
}

.at-mt-5 {
  margin-block-start: 1.25rem; //20px
}

.at-mt-6 {
  margin-block-start: 1.5rem; //24px
}

.at-mt-8 {
  margin-block-start: 2rem; //32px
}

.at-mt-10 {
  margin-block-start: 3rem; //48px
}

.at-mb-1 {
  margin-block-end: 0.25rem; //4px
}

.at-mb-2 {
  margin-block-end: 0.5rem; //8px
}

.at-mb-3 {
  margin-block-end: 0.75rem; //12px
}

.at-mb-4 {
  margin-block-end: 1rem; //16px
}

.at-mb-5 {
  margin-block-end: 1.25rem; //20px
}

.at-mb-6 {
  margin-block-end: 1.5rem; //24px
}

.at-mb-8 {
  margin-block-end: 2rem; //32px
}

.at-mb-9 {
  margin-block-end: 2.5rem; //40px
}

.at-mb-10 {
  margin-block-end: 3rem; //48px
}

.at-mb-0 {
  margin-block-end: 0rem; //4px
}
