/* You can add global styles to this file, and also import other style files */
@import './ui-components/src/assets/styles/main.scss';

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src:
    local('Helvetica Neue'),
    url(https://fonts.cdnfonts.com/s/14188/HelveticaNeue-Roman.woff)
      format('woff');
}

@font-face {
  font-family: 'Helvetica Neue 500';
  font-style: normal;
  font-weight: 500;
  src:
    local('Helvetica Neue 500'),
    url(https://fonts.cdnfonts.com/s/14188/HelveticaNeueMedium.woff)
      format('woff');
}

@font-face {
  font-family: 'Helvetica Neue 700';
  font-style: normal;
  font-weight: 700;
  src:
    local('Helvetica Neue 700'),
    url(https://fonts.cdnfonts.com/s/14188/HelveticaNeueBold.woff)
      format('woff');
}

:root {
  --font-family-main: 'Helvetica Neue', sans-serif; //400
  --font-family-semi-bold: 'Helvetica Neue 500', sans-serif; //500
  --font-family-bold: 'Helvetica Neue 700', sans-serif; //700
  --header-height: 5.5rem; //88px
}

* {
  font-family: var(--font-family-main);
  padding: 0;
  margin: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body#ecommerce-02 {
  font-size: var(--paragraph-regular);
  color: var(--primary1);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100%;

  @media screen and (pointer: fine) {
    padding-inline-end: 1rem; //for the scrollbar
  }
}

.boxo-logo {
  aspect-ratio: 184/40;
  height: auto;
  width: 11.5rem; //184px
}

.at-fw-500 {
  font-family: var(--font-family-semi-bold);
}

.at-fw-700 {
  font-family: var(--font-family-bold);
}

.body-no-scroll {
  overflow: hidden;
}

.page-container {
  margin-top: calc(var(--header-height) + 3.75rem); //88px + 60px
  margin-inline: 6.5rem; //104px
  margin-bottom: 3rem; //48px

  @media screen and (min-width: 1920px) {
    max-width: calc(1920px - 6.5rem);
    margin-inline: auto;
  }
}

.less-than-w-50 {
  width: calc(50% - 0.5rem);
}

.public-page-container {
  padding: 9.25rem 8.25rem; //148px //132px

  @media screen and (min-width: 1920px) {
    max-width: calc(1920px - 8.25rem);
    margin-inline: auto;
  }
}

.trade-page-container {
  padding: 4.75rem 2rem; //76px 32px
  height: calc(100vh - 3.75rem + 1px);

  @media screen and (min-width: 768px) {
    padding-inline: 3.5rem; //56px
  }

  @media screen and (min-width: 1360px) {
    padding-inline: 10rem; //160px
  }

  @media screen and (min-width: 1920px) {
    max-width: calc(1920px - 13rem);
    margin-inline: auto;
  }
}

.at-mb-2_5rem {
  margin-bottom: 2.5rem; //40px
}

.header-4rem {
  font-size: 4rem;
}

.public-page-card {
  padding: 2.5rem 5rem;
}

.flip-vertical {
  transform: scaleY(-1);
  -o-transform: scaleY(-1);
}

.btn:disabled {
  cursor: not-allowed;
  border: 1px solid var(--secondary2);
  background: var(--secondary2);
  color: var(--secondary4);
}
