@media screen and (min-width: 576px) {
  .at-flex-sm-wrap {
    flex-wrap: wrap;
  }

  .at-flex-sm-column {
    flex-direction: column;
  }

  .at-flex-sm-row {
    flex-direction: row;
  }

  .at-justify-sm-center {
    justify-content: center;
  }

  .at-justify-sm-between {
    justify-content: space-between;
  }

  .at-justify-sm-end {
    justify-content: flex-end;
  }

  .at-align-sm-items-center {
    align-items: center;
  }

  .at-align-sm-items-start {
    align-items: flex-start;
  }

  .at-align-sm-items-end {
    align-items: flex-end;
  }

  .at-align-sm-self-stretch {
    align-self: stretch;
  }

  .at-col-sm {
    flex: 1 0 0%;
  }

  .at-col-sm-auto {
    flex: 0 0 auto;
  }

  .at-col-sm-2 {
    flex: 0 0 auto;
    width: 20%;
  }

  .at-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .at-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .at-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .at-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .at-col-sm-7 {
    flex: 0 0 auto;
    width: calc(100% - 41.66666667%);
  }

  .at-col-sm-8 {
    flex: 0 0 auto;
    width: calc(100% - 33.33333333%);
  }

  .at-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .at-gap-sm-1 {
    gap: 0.25rem; //4px
  }

  .at-gap-sm-2 {
    gap: 0.5rem; //8px
  }

  .at-gap-sm-4 {
    gap: 1rem; //16px
  }

  .at-gap-sm-6 {
    gap: 1.5rem; //24px
  }

  .at-gap-sm-8 {
    gap: 2rem; //32px
  }

  .at-gap-sm-9 {
    gap: 2.5rem; //40px
  }
}

@media screen and (min-width: 768px) {
  .at-flex-md-wrap {
    flex-wrap: wrap;
  }

  .at-flex-md-column {
    flex-direction: column;
  }

  .at-flex-md-row {
    flex-direction: row;
  }

  .at-justify-md-center {
    justify-content: center;
  }

  .at-justify-md-between {
    justify-content: space-between;
  }

  .at-justify-md-end {
    justify-content: flex-end;
  }

  .at-align-md-items-center {
    align-items: center;
  }

  .at-align-md-items-start {
    align-items: flex-start;
  }

  .at-align-md-items-end {
    align-items: flex-end;
  }

  .at-align-md-self-stretch {
    align-self: stretch;
  }

  .at-col-md {
    flex: 1 0 0%;
  }

  .at-col-md-auto {
    flex: 0 0 auto;
  }

  .at-col-md-2 {
    flex: 0 0 auto;
    width: 20%;
  }

  .at-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .at-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .at-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .at-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .at-col-md-7 {
    flex: 0 0 auto;
    width: calc(100% - 41.66666667%);
  }

  .at-col-md-8 {
    flex: 0 0 auto;
    width: calc(100% - 33.33333333%);
  }

  .at-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .at-gap-md-1 {
    gap: 0.25rem; //4px
  }

  .at-gap-md-2 {
    gap: 0.5rem; //8px
  }

  .at-gap-md-4 {
    gap: 1rem; //16px
  }

  .at-gap-md-6 {
    gap: 1.5rem; //24px
  }

  .at-gap-md-8 {
    gap: 2rem; //32px
  }

  .at-gap-md-9 {
    gap: 2.5rem; //40px
  }

  .at-order-md-2 {
    order: 2;
  }

  .at-order-md-1 {
    order: 1;
  }

  .at-order-md-0 {
    order: 0;
  }
}

@media screen and (min-width: 1200px) {
  .at-flex-xl-wrap {
    flex-wrap: wrap;
  }

  .at-flex-xl-column {
    flex-direction: column;
  }

  .at-flex-xl-row {
    flex-direction: row;
  }

  .at-col-xl {
    flex: 1 0 0%;
  }

  .at-col-xl-auto {
    flex: 0 0 auto;
  }

  .at-col-xl-2 {
    flex: 0 0 auto;
    width: 20%;
  }

  .at-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .at-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .at-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .at-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .at-col-xl-7 {
    flex: 0 0 auto;
    width: calc(100% - 41.66666667%);
  }

  .at-col-xl-8 {
    flex: 0 0 auto;
    width: calc(100% - 33.33333333%);
  }

  .at-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .at-order-xl-2 {
    order: 2;
  }

  .at-order-xl-1 {
    order: 1;
  }

  .at-order-xl-0 {
    order: 0;
  }
}

@media screen and (min-width: 1400px) {
  .at-col-xxl {
    flex: 1 0 0%;
  }

  .at-col-xxl-auto {
    flex: 0 0 auto;
  }

  .at-col-xxl-2 {
    flex: 0 0 auto;
    width: 20%;
  }

  .at-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .at-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .at-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .at-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .at-col-xxl-7 {
    flex: 0 0 auto;
    width: calc(100% - 41.66666667%);
  }

  .at-col-xxl-8 {
    flex: 0 0 auto;
    width: calc(100% - 33.33333333%);
  }

  .at-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
