@media screen and (min-width: 768px) {
  .at-p-md-1 {
    padding: 0.25rem; //4px
  }

  .at-p-md-2 {
    padding: 0.5rem; //8px
  }

  .at-p-md-3 {
    padding: 0.75rem; //12px
  }

  .at-p-md-4 {
    padding: 1rem; //16px
  }

  .at-p-md-5 {
    padding: 1.25rem; //20px
  }

  .at-p-md-6 {
    padding: 1.5rem; //24px
  }

  .at-p-md-8 {
    padding: 2rem; //32px
  }

  .at-p-md-10 {
    padding: 3rem; //48px
  }

  .at-p-md-0 {
    padding: 0;
  }

  .at-px-md-1 {
    padding-inline: 0.25rem; //4px
  }

  .at-px-md-2 {
    padding-inline: 0.5rem; //8px
  }

  .at-px-md-3 {
    padding-inline: 0.75rem; //12px
  }

  .at-px-md-4 {
    padding-inline: 1rem; //16px
  }

  .at-px-md-5 {
    padding-inline: 1.25rem; //20px
  }

  .at-px-md-6 {
    padding-inline: 1.5rem; //24px
  }

  .at-px-md-7 {
    padding-inline: 1.75rem; //28px
  }

  .at-px-md-8 {
    padding-inline: 2rem; //32px
  }

  .at-px-md-9 {
    padding-inline: 2.5rem; //40px
  }

  .at-px-md-10 {
    padding-inline: 3rem; //48px
  }

  .at-px-md-0 {
    padding-inline: 0;
  }

  .at-py-md-1 {
    padding-block: 0.25rem; //4px
  }

  .at-py-md-2 {
    padding-block: 0.5rem; //8px
  }

  .at-py-md-3 {
    padding-block: 0.75rem; //12px
  }

  .at-py-md-4 {
    padding-block: 1rem; //16px
  }

  .at-py-md-5 {
    padding-block: 1.25rem; //20px
  }

  .at-py-md-6 {
    padding-block: 1.5rem; //24px
  }

  .at-py-md-8 {
    padding-block: 2rem; //32px
  }

  .at-py-md-9 {
    padding-block: 2.5rem; //40px
  }

  .at-py-md-10 {
    padding-block: 3rem; //48px
  }

  .at-ps-md-1 {
    padding-inline-start: 0.25rem; //4px
  }

  .at-ps-md-2 {
    padding-inline-start: 0.5rem; //8px
  }

  .at-ps-md-3 {
    padding-inline-start: 0.75rem; //12px
  }

  .at-ps-md-4 {
    padding-inline-start: 1rem; //16px
  }

  .at-ps-md-5 {
    padding-inline-start: 1.25rem; //20px
  }

  .at-ps-md-6 {
    padding-inline-start: 1.5rem; //24px
  }

  .at-ps-md-8 {
    padding-inline-start: 2rem; //32px
  }

  .at-ps-md-9 {
    padding-inline-start: 2.5rem; //40px
  }

  .at-ps-md-10 {
    padding-inline-start: 3rem; //48px
  }

  .at-pe-md-1 {
    padding-inline-end: 0.25rem; //4px
  }

  .at-pe-md-2 {
    padding-inline-end: 0.5rem; //8px
  }

  .at-pe-md-3 {
    padding-inline-end: 0.75rem; //12px
  }

  .at-pe-md-4 {
    padding-inline-end: 1rem; //16px
  }

  .at-pe-md-5 {
    padding-inline-end: 1.25rem; //20px
  }

  .at-pe-md-6 {
    padding-inline-end: 1.5rem; //24px
  }

  .at-pe-md-8 {
    padding-inline-end: 2rem; //32px
  }

  .at-pe-md-10 {
    padding-inline-end: 3rem; //48px
  }

  .at-pt-md-1 {
    padding-block-start: 0.25rem; //4px
  }

  .at-pt-md-2 {
    padding-block-start: 0.5rem; //8px
  }

  .at-pt-md-3 {
    padding-block-start: 0.75rem; //12px
  }

  .at-pt-md-4 {
    padding-block-start: 1rem; //16px
  }

  .at-pt-md-5 {
    padding-block-start: 1.25rem; //20px
  }

  .at-pt-md-6 {
    padding-block-start: 1.5rem; //24px
  }

  .at-pt-md-8 {
    padding-block-start: 2rem; //32px
  }

  .at-pt-md-9 {
    padding-block-start: 2.5rem; //40px
  }

  .at-pt-md-10 {
    padding-block-start: 3rem; //48px
  }

  .at-pb-md-1 {
    padding-block-end: 0.25rem; //4px
  }

  .at-pb-md-2 {
    padding-block-end: 0.5rem; //8px
  }

  .at-pb-md-3 {
    padding-block-end: 0.75rem; //12px
  }

  .at-pb-md-4 {
    padding-block-end: 1rem; //16px
  }

  .at-pb-md-5 {
    padding-block-end: 1.25rem; //20px
  }

  .at-pb-md-6 {
    padding-block-end: 1.5rem; //24px
  }

  .at-pb-md-8 {
    padding-block-end: 2rem; //32px
  }

  .at-pb-md-9 {
    padding-block-end: 2.5rem; //40px
  }

  .at-pb-md-10 {
    padding-block-end: 3rem; //48px
  }
}

@media screen and (min-width: 1200px) {
  .at-p-xl-1 {
    padding: 0.25rem; //4px
  }

  .at-p-xl-2 {
    padding: 0.5rem; //8px
  }

  .at-p-xl-3 {
    padding: 0.75rem; //12px
  }

  .at-p-xl-4 {
    padding: 1rem; //16px
  }

  .at-p-xl-5 {
    padding: 1.25rem; //20px
  }

  .at-p-xl-6 {
    padding: 1.5rem; //24px
  }

  .at-p-xl-8 {
    padding: 2rem; //32px
  }

  .at-p-xl-10 {
    padding: 3rem; //48px
  }

  .at-p-xl-0 {
    padding: 0;
  }

  .at-px-xl-1 {
    padding-inline: 0.25rem; //4px
  }

  .at-px-xl-2 {
    padding-inline: 0.5rem; //8px
  }

  .at-px-xl-3 {
    padding-inline: 0.75rem; //12px
  }

  .at-px-xl-4 {
    padding-inline: 1rem; //16px
  }

  .at-px-xl-5 {
    padding-inline: 1.25rem; //20px
  }

  .at-px-xl-6 {
    padding-inline: 1.5rem; //24px
  }

  .at-px-xl-7 {
    padding-inline: 1.75rem; //28px
  }

  .at-px-xl-8 {
    padding-inline: 2rem; //32px
  }

  .at-px-xl-9 {
    padding-inline: 2.5rem; //40px
  }

  .at-px-xl-10 {
    padding-inline: 3rem; //48px
  }

  .at-px-xl-0 {
    padding-inline: 0;
  }

  .at-py-xl-1 {
    padding-block: 0.25rem; //4px
  }

  .at-py-xl-2 {
    padding-block: 0.5rem; //8px
  }

  .at-py-xl-3 {
    padding-block: 0.75rem; //12px
  }

  .at-py-xl-4 {
    padding-block: 1rem; //16px
  }

  .at-py-xl-5 {
    padding-block: 1.25rem; //20px
  }

  .at-py-xl-6 {
    padding-block: 1.5rem; //24px
  }

  .at-py-xl-8 {
    padding-block: 2rem; //32px
  }

  .at-py-xl-9 {
    padding-block: 2.5rem; //40px
  }

  .at-py-xl-10 {
    padding-block: 3rem; //48px
  }

  .at-ps-xl-1 {
    padding-inline-start: 0.25rem; //4px
  }

  .at-ps-xl-2 {
    padding-inline-start: 0.5rem; //8px
  }

  .at-ps-xl-3 {
    padding-inline-start: 0.75rem; //12px
  }

  .at-ps-xl-4 {
    padding-inline-start: 1rem; //16px
  }

  .at-ps-xl-5 {
    padding-inline-start: 1.25rem; //20px
  }

  .at-ps-xl-6 {
    padding-inline-start: 1.5rem; //24px
  }

  .at-ps-xl-8 {
    padding-inline-start: 2rem; //32px
  }

  .at-ps-xl-9 {
    padding-inline-start: 2.5rem; //40px
  }

  .at-ps-xl-10 {
    padding-inline-start: 3rem; //48px
  }

  .at-pe-xl-1 {
    padding-inline-end: 0.25rem; //4px
  }

  .at-pe-xl-2 {
    padding-inline-end: 0.5rem; //8px
  }

  .at-pe-xl-3 {
    padding-inline-end: 0.75rem; //12px
  }

  .at-pe-xl-4 {
    padding-inline-end: 1rem; //16px
  }

  .at-pe-xl-5 {
    padding-inline-end: 1.25rem; //20px
  }

  .at-pe-xl-6 {
    padding-inline-end: 1.5rem; //24px
  }

  .at-pe-xl-8 {
    padding-inline-end: 2rem; //32px
  }

  .at-pe-xl-10 {
    padding-inline-end: 3rem; //48px
  }

  .at-pt-xl-1 {
    padding-block-start: 0.25rem; //4px
  }

  .at-pt-xl-2 {
    padding-block-start: 0.5rem; //8px
  }

  .at-pt-xl-3 {
    padding-block-start: 0.75rem; //12px
  }

  .at-pt-xl-4 {
    padding-block-start: 1rem; //16px
  }

  .at-pt-xl-5 {
    padding-block-start: 1.25rem; //20px
  }

  .at-pt-xl-6 {
    padding-block-start: 1.5rem; //24px
  }

  .at-pt-xl-8 {
    padding-block-start: 2rem; //32px
  }

  .at-pt-xl-9 {
    padding-block-start: 2.5rem; //40px
  }

  .at-pt-xl-10 {
    padding-block-start: 3rem; //48px
  }

  .at-pb-xl-1 {
    padding-block-end: 0.25rem; //4px
  }

  .at-pb-xl-2 {
    padding-block-end: 0.5rem; //8px
  }

  .at-pb-xl-3 {
    padding-block-end: 0.75rem; //12px
  }

  .at-pb-xl-4 {
    padding-block-end: 1rem; //16px
  }

  .at-pb-xl-5 {
    padding-block-end: 1.25rem; //20px
  }

  .at-pb-xl-6 {
    padding-block-end: 1.5rem; //24px
  }

  .at-pb-xl-8 {
    padding-block-end: 2rem; //32px
  }

  .at-pb-xl-9 {
    padding-block-end: 2.5rem; //40px
  }

  .at-pb-xl-10 {
    padding-block-end: 3rem; //48px
  }
}

@media screen and (min-width: 1400px) {
  .at-p-xxl-1 {
    padding: 0.25rem; //4px
  }

  .at-p-xxl-2 {
    padding: 0.5rem; //8px
  }

  .at-p-xxl-3 {
    padding: 0.75rem; //12px
  }

  .at-p-xxl-4 {
    padding: 1rem; //16px
  }

  .at-p-xxl-5 {
    padding: 1.25rem; //20px
  }

  .at-p-xxl-6 {
    padding: 1.5rem; //24px
  }

  .at-p-xxl-8 {
    padding: 2rem; //32px
  }

  .at-p-xxl-10 {
    padding: 3rem; //48px
  }

  .at-p-xxl-0 {
    padding: 0;
  }

  .at-px-xxl-1 {
    padding-inline: 0.25rem; //4px
  }

  .at-px-xxl-2 {
    padding-inline: 0.5rem; //8px
  }

  .at-px-xxl-3 {
    padding-inline: 0.75rem; //12px
  }

  .at-px-xxl-4 {
    padding-inline: 1rem; //16px
  }

  .at-px-xxl-5 {
    padding-inline: 1.25rem; //20px
  }

  .at-px-xxl-6 {
    padding-inline: 1.5rem; //24px
  }

  .at-px-xxl-7 {
    padding-inline: 1.75rem; //28px
  }

  .at-px-xxl-8 {
    padding-inline: 2rem; //32px
  }

  .at-px-xxl-9 {
    padding-inline: 2.5rem; //40px
  }

  .at-px-xxl-10 {
    padding-inline: 3rem; //48px
  }

  .at-px-xxl-0 {
    padding-inline: 0;
  }

  .at-py-xxl-1 {
    padding-block: 0.25rem; //4px
  }

  .at-py-xxl-2 {
    padding-block: 0.5rem; //8px
  }

  .at-py-xxl-3 {
    padding-block: 0.75rem; //12px
  }

  .at-py-xxl-4 {
    padding-block: 1rem; //16px
  }

  .at-py-xxl-5 {
    padding-block: 1.25rem; //20px
  }

  .at-py-xxl-6 {
    padding-block: 1.5rem; //24px
  }

  .at-py-xxl-8 {
    padding-block: 2rem; //32px
  }

  .at-py-xxl-9 {
    padding-block: 2.5rem; //40px
  }

  .at-py-xxl-10 {
    padding-block: 3rem; //48px
  }

  .at-ps-xxl-1 {
    padding-inline-start: 0.25rem; //4px
  }

  .at-ps-xxl-2 {
    padding-inline-start: 0.5rem; //8px
  }

  .at-ps-xxl-3 {
    padding-inline-start: 0.75rem; //12px
  }

  .at-ps-xxl-4 {
    padding-inline-start: 1rem; //16px
  }

  .at-ps-xxl-5 {
    padding-inline-start: 1.25rem; //20px
  }

  .at-ps-xxl-6 {
    padding-inline-start: 1.5rem; //24px
  }

  .at-ps-xxl-8 {
    padding-inline-start: 2rem; //32px
  }

  .at-ps-xxl-9 {
    padding-inline-start: 2.5rem; //40px
  }

  .at-ps-xxl-10 {
    padding-inline-start: 3rem; //48px
  }

  .at-pe-xxl-1 {
    padding-inline-end: 0.25rem; //4px
  }

  .at-pe-xxl-2 {
    padding-inline-end: 0.5rem; //8px
  }

  .at-pe-xxl-3 {
    padding-inline-end: 0.75rem; //12px
  }

  .at-pe-xxl-4 {
    padding-inline-end: 1rem; //16px
  }

  .at-pe-xxl-5 {
    padding-inline-end: 1.25rem; //20px
  }

  .at-pe-xxl-6 {
    padding-inline-end: 1.5rem; //24px
  }

  .at-pe-xxl-8 {
    padding-inline-end: 2rem; //32px
  }

  .at-pe-xxl-10 {
    padding-inline-end: 3rem; //48px
  }

  .at-pt-xxl-1 {
    padding-block-start: 0.25rem; //4px
  }

  .at-pt-xxl-2 {
    padding-block-start: 0.5rem; //8px
  }

  .at-pt-xxl-3 {
    padding-block-start: 0.75rem; //12px
  }

  .at-pt-xxl-4 {
    padding-block-start: 1rem; //16px
  }

  .at-pt-xxl-5 {
    padding-block-start: 1.25rem; //20px
  }

  .at-pt-xxl-6 {
    padding-block-start: 1.5rem; //24px
  }

  .at-pt-xxl-8 {
    padding-block-start: 2rem; //32px
  }

  .at-pt-xxl-9 {
    padding-block-start: 2.5rem; //40px
  }

  .at-pt-xxl-10 {
    padding-block-start: 3rem; //48px
  }

  .at-pb-xxl-1 {
    padding-block-end: 0.25rem; //4px
  }

  .at-pb-xxl-2 {
    padding-block-end: 0.5rem; //8px
  }

  .at-pb-xxl-3 {
    padding-block-end: 0.75rem; //12px
  }

  .at-pb-xxl-4 {
    padding-block-end: 1rem; //16px
  }

  .at-pb-xxl-5 {
    padding-block-end: 1.25rem; //20px
  }

  .at-pb-xxl-6 {
    padding-block-end: 1.5rem; //24px
  }

  .at-pb-xxl-8 {
    padding-block-end: 2rem; //32px
  }

  .at-pb-xxl-9 {
    padding-block-end: 2.5rem; //40px
  }

  .at-pb-xxl-10 {
    padding-block-end: 3rem; //48px
  }
}
