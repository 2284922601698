.at-text-primary1 {
  color: var(--primary1);
}

.at-text-primary2 {
  color: var(--primary2);
}

.at-text-secondary1 {
  color: var(--secondary1);
}

.at-text-secondary2 {
  color: var(--secondary2);
}

.at-text-secondary3 {
  color: var(--secondary3);
}

.at-text-secondary4 {
  color: var(--secondary4);
}

.at-text-secondary5 {
  color: var(--secondary5);
}

.at-text-secondary6 {
  color: var(--secondary6);
}

.at-text-success {
  color: var(--success);
}

.at-text-failure {
  color: var(--failure);
}

.at-text-warning {
  color: var(--warning);
}

.at-text-information {
  color: var(--information);
}

.at-text-hint {
  color: var(--text-hint);
}

.at-text-white {
  color: var(--text-white);
}

.at-text-secondary {
  color: var(--text-secondary);
}

.at-text-status-pending {
  color: var(--status-pending);
}

.at-text-status-declined {
  color: var(--status-declined);
}

.at-text-status-accepted {
  color: var(--status-accepted);
}

.at-text-status-delayed {
  color: var(--status-delayed);
}

.at-text-status-shipped {
  color: var(--status-shipped);
}

.at-text-status-delivered {
  color: var(--status-delivered);
}

.at-text-primary1-hover:hover {
  color: var(--primary1);
}

.at-text-primary2-hover:hover {
  color: var(--primary2);
}

.at-text-hint-hover:hover {
  color: var(--text-hint);
}
