:root {
  --primary1: #101b19;
  --primary2: #006cff;

  --secondary1: #101b1908; // 3%
  --secondary2: #101b190d; // 5%
  --secondary3: #e7e8e8; // 10%
  --secondary4: #101b1933; // 20%
  --secondary5: #b7bbba; // 30%
  --secondary6: #101b1966; // 40%

  --secondary1-p2: #f7fbff; // 3%
  --secondary2-p2: #101b190d; // 5%
  --secondary3-p2: #101b191a; // 10%
  --secondary4-p2: #101b1933; // 20%
  --secondary5-p2: #101b194d; // 30%
  --secondary6-p2: #101b1966; // 40%

  --success: #2e7d32;
  --failure: #c62828;
  --warning: #f9a825;
  --information: #0277bd;

  --success2: #f5f8f5;
  --failure2: #fcf4f4;
  --warning2: #fffbf4;
  --information2: #f2f8fc;

  --text-hint: #707675;
  --text-white: #ffffff;
  --text-secondary: #e3ddd8;

  --status-pending: #e3ddd8;
  --status-declined: #f0d6cc;
  --status-accepted: #ccd7da;
  --status-delayed: #f0d6a5;
  --status-shipped: #c7bfc9;
  --status-delivered: #c7eea2;
  --status-rejected: #ea0e120d;
}
