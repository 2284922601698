:root {
  /* header */
  --header1: 2rem; //32
  --header2: 1.5rem; //24
  --header3: 1.25rem; //20
  --header4: 16px; //16
  --header5: 0.875rem; //14
  --header6: 0.75rem; //12

  /* paragraph */
  --paragraph-regular: 16px; //16
  --paragraph-small: 0.875rem; //14
  --paragraph-extra-small: 0.75rem; //12
  --paragraph-mega-small: 0.625rem; //10

  --font-family-main: 'Helvetica Neue', sans-serif; //400
  --font-family-semi-bold: 'Helvetica Neue 500', sans-serif; //500
  --font-family-bold: 'Helvetica Neue 700', sans-serif; //700
}
