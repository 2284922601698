button {
  border: 0;
  background: transparent;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

a {
  text-decoration: none;
  color: var(--primary1);
}

.at-rounded-pill {
  border-radius: 50rem;
}

.at-rounded-circle {
  border-radius: 50%;
}

.at-rounded-1 {
  border-radius: 0.25rem; // 4px
}

.at-rounded-2 {
  border-radius: 0.5rem; // 8px
}

.at-rounded-4 {
  border-radius: 1rem; // 16px
}

.at-rounded-5 {
  border-radius: 1.25rem; // 20px
}

.at-rounded-6 {
  border-radius: 1.5rem; // 24px
}

.at-top-rounded-2 {
  border-start-start-radius: 0.5rem;
  border-start-end-radius: 0.5rem;
}

.at-top-rounded-4 {
  border-start-start-radius: 1rem;
  border-start-end-radius: 1rem;
}

.at-bottom-rounded-4 {
  border-end-start-radius: 1rem;
  border-end-end-radius: 1rem;
}

.at-text-center {
  text-align: center;
}

.at-text-start {
  text-align: start;
}

.at-text-end {
  text-align: end;
}

.at-d-block {
  display: block;
}

.at-d-inline-block {
  display: inline-block;
}

.at-d-flex {
  display: flex;
}

.at-d-none {
  display: none;
}
.at-w-25 {
  width: 25%;
}

.at-w-50 {
  width: 50%;
}

.at-w-75 {
  width: 75%;
}

.at-w-100 {
  width: 100%;
}

.at-w-auto {
  width: auto;
}

.at-position-relative {
  position: relative;
}

.at-position-absolute {
  position: absolute;
}

.at-tooltip {
  position: relative;

  .at-tooltip-text {
    visibility: hidden;
    min-width: 11rem;
    border-radius: 0.25rem;
    padding: 0.375rem;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: var(--paragraph-extra-small);
    text-align: start;

    &::after {
      content: '';
      position: absolute;
      border-width: 0.25rem;
      border-style: solid;
    }
  }

  &.top {
    .at-tooltip-text {
      bottom: 125%;
      inset-inline-start: 50%;
      margin-inline-start: -5.5rem;

      &::after {
        top: 100%;
        inset-inline-start: 50%;
        margin-inline-start: -0.25rem;
        border-color: var(--primary1) transparent transparent transparent;
      }
    }
  }

  &.start {
    .at-tooltip-text {
      bottom: -100%;
      inset-inline-end: 150%;

      &::after {
        top: 50%;
        inset-inline-end: -4%;
        border-color: transparent transparent transparent var(--primary1);
      }
    }
  }

  &.bottom {
    .at-tooltip-text {
      top: 125%;
      inset-inline-start: 50%;
      margin-inline-start: -5.5rem;

      &::after {
        bottom: 100%;
        inset-inline-start: 50%;
        margin-inline-start: -0.25rem;
        border-color: transparent transparent var(--primary1) transparent;
      }
    }
  }

  &.end {
    .at-tooltip-text {
      bottom: -125%;
      inset-inline-start: 150%;

      &::after {
        top: 50%;
        inset-inline-start: -4%;
        border-color: transparent var(--primary1) transparent transparent;
      }
    }
  }

  &.no-tail {
    .at-tooltip-text::after {
      border-color: transparent;
    }
  }

  &:hover .at-tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}

.field-info {
  position: absolute;
  inset-inline-start: calc(100% + 0.5rem);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.at-pointer {
  cursor: pointer;
}

.at-toast {
  padding: 1rem;
  border-radius: 0.5rem;
  color: var(--text-white);
  font-size: var(--paragraph-extra-small);
  font-weight: 400;
  position: fixed;
  top: 0.5rem;
  inset-inline-end: 0.5rem;
  display: none;
  transition: 0.5s opacity ease-in;
  opacity: 0;
  z-index: 3;

  &.show {
    display: flex;
    align-items: center;
    opacity: 1;
    z-index: 1001;
  }

  .at-toast-message {
    max-width: 17.5rem;
  }
}

.at-object-contain {
  object-fit: contain;
  -o-object-fit: contain;
}

.at-object-cover {
  object-fit: cover;
  -o-object-fit: cover;
}

.at-text-decoration-underline {
  text-decoration: underline;
}

.at-h-75 {
  height: calc(75vh - 60px);
}

.at-h-100 {
  height: calc(100vh - 60px);
}

.at-opacity-0 {
  opacity: 0;
}

.at-opacity-50 {
  opacity: 50%;
}

.at-of-hidden {
  overflow: hidden;
}

.at-line-height-100 {
  line-height: 100%;
}

.at-line-height-150 {
  line-height: 150%;
}

.at-text-capitalize {
  text-transform: capitalize;
}

.at-appearance-none {
  appearance: none;
}

.at-outline-unset {
  outline: unset;
}

.at-cursor-pointer {
  cursor: pointer;
}
