.at-border-primary1 {
  border-color: var(--primary1) !important;
}

.at-border-primary2 {
  border-color: var(--primary2);
}

.at-border-secondary1 {
  border-color: var(--secondary1);
}

.at-border-secondary2 {
  border-color: var(--secondary2);
}

.at-border-secondary3 {
  border-color: var(--secondary3);
}

.at-border-secondary4 {
  border-color: var(--secondary4) !important;
}

.at-border-secondary5 {
  border-color: var(--secondary5);
}

.at-border-secondary6 {
  border-color: var(--secondary6);
}

.at-border-success {
  border-color: var(--success);
}

.at-border-failure {
  border-color: var(--failure);
}

.at-border-warning {
  border-color: var(--warning);
}

.at-border-information {
  border-color: var(--information);
}

.at-border-status-pending {
  border-color: var(--status-pending);
}

.at-border-status-declined {
  border-color: var(--status-declined);
}

.at-border-status-accepted {
  border-color: var(--status-accepted);
}

.at-border-status-delayed {
  border-color: var(--status-delayed);
}

.at-border-status-shipped {
  border-color: var(--status-shipped);
}

.at-border-status-delivered {
  border-color: var(--status-delivered);
}
