.at-p-1 {
  padding: 0.25rem; //4px
}

.at-p-2 {
  padding: 0.5rem; //8px
}

.at-p-3 {
  padding: 0.75rem; //12px
}

.at-p-4 {
  padding: 1rem; //16px
}

.at-p-5 {
  padding: 1.25rem; //20px
}

.at-p-6 {
  padding: 1.5rem; //24px
}

.at-p-8 {
  padding: 2rem; //32px
}

.at-p-10 {
  padding: 3rem; //48px
}

.at-p-0 {
  padding: 0;
}

.at-px-1 {
  padding-inline: 0.25rem; //4px
}

.at-px-2 {
  padding-inline: 0.5rem; //8px
}

.at-px-3 {
  padding-inline: 0.75rem; //12px
}

.at-px-4 {
  padding-inline: 1rem; //16px
}

.at-px-5 {
  padding-inline: 1.25rem; //20px
}

.at-px-6 {
  padding-inline: 1.5rem; //24px
}

.at-px-7 {
  padding-inline: 1.75rem; //28px
}

.at-px-8 {
  padding-inline: 2rem; //32px
}

.at-px-9 {
  padding-inline: 2.5rem; //40px
}

.at-px-10 {
  padding-inline: 3rem; //48px
}

.at-px-0 {
  padding-inline: 0;
}

.at-py-1 {
  padding-block: 0.25rem; //4px
}

.at-py-2 {
  padding-block: 0.5rem; //8px
}

.at-py-3 {
  padding-block: 0.75rem; //12px
}

.at-py-4 {
  padding-block: 1rem; //16px
}

.at-py-5 {
  padding-block: 1.25rem; //20px
}

.at-py-6 {
  padding-block: 1.5rem; //24px
}

.at-py-8 {
  padding-block: 2rem; //32px
}

.at-py-9 {
  padding-block: 2.5rem; //40px
}

.at-py-10 {
  padding-block: 3rem; //48px
}

.at-ps-1 {
  padding-inline-start: 0.25rem; //4px
}

.at-ps-2 {
  padding-inline-start: 0.5rem; //8px
}

.at-ps-3 {
  padding-inline-start: 0.75rem; //12px
}

.at-ps-4 {
  padding-inline-start: 1rem; //16px
}

.at-ps-5 {
  padding-inline-start: 1.25rem; //20px
}

.at-ps-6 {
  padding-inline-start: 1.5rem; //24px
}

.at-ps-8 {
  padding-inline-start: 2rem; //32px
}

.at-ps-9 {
  padding-inline-start: 2.5rem; //40px
}

.at-ps-10 {
  padding-inline-start: 3rem; //48px
}

.at-pe-1 {
  padding-inline-end: 0.25rem; //4px
}

.at-pe-2 {
  padding-inline-end: 0.5rem; //8px
}

.at-pe-3 {
  padding-inline-end: 0.75rem; //12px
}

.at-pe-4 {
  padding-inline-end: 1rem; //16px
}

.at-pe-5 {
  padding-inline-end: 1.25rem; //20px
}

.at-pe-6 {
  padding-inline-end: 1.5rem; //24px
}

.at-pe-8 {
  padding-inline-end: 2rem; //32px
}

.at-pe-10 {
  padding-inline-end: 3rem; //48px
}

.at-pt-1 {
  padding-block-start: 0.25rem; //4px
}

.at-pt-2 {
  padding-block-start: 0.5rem; //8px
}

.at-pt-3 {
  padding-block-start: 0.75rem; //12px
}

.at-pt-4 {
  padding-block-start: 1rem; //16px
}

.at-pt-5 {
  padding-block-start: 1.25rem; //20px
}

.at-pt-6 {
  padding-block-start: 1.5rem; //24px
}

.at-pt-8 {
  padding-block-start: 2rem; //32px
}

.at-pt-9 {
  padding-block-start: 2.5rem; //40px
}

.at-pt-10 {
  padding-block-start: 3rem; //48px
}

.at-pb-1 {
  padding-block-end: 0.25rem; //4px
}

.at-pb-2 {
  padding-block-end: 0.5rem; //8px
}

.at-pb-3 {
  padding-block-end: 0.75rem; //12px
}

.at-pb-4 {
  padding-block-end: 1rem; //16px
}

.at-pb-5 {
  padding-block-end: 1.25rem; //20px
}

.at-pb-6 {
  padding-block-end: 1.5rem; //24px
}

.at-pb-8 {
  padding-block-end: 2rem; //32px
}

.at-pb-9 {
  padding-block-end: 2.5rem; //40px
}

.at-pb-10 {
  padding-block-end: 3rem; //48px
}
