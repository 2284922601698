@media screen and (min-width: 768px) {
  .at-border-md-bottom {
    border-block-end-width: 1px;
    border-block-end-style: solid;
  }
}

@media screen and (min-width: 1200px) {
  .at-border-xl-bottom {
    border-block-end-width: 1px;
    border-block-end-style: solid;
  }
}
