@media screen and (min-width: 768px) {
  .at-d-md-none {
    display: none;
  }

  .at-d-md-block {
    display: block;
  }

  .at-d-md-inline-block {
    display: inline-block;
  }

  .at-d-md-flex {
    display: flex;
  }

  .at-text-md-center {
    text-align: center;
  }

  .at-text-md-start {
    text-align: start;
  }

  .at-text-md-end {
    text-align: end;
  }
}

@media screen and (min-width: 992px) {
  .at-d-lg-none {
    display: none;
  }

  .at-d-lg-block {
    display: block;
  }

  .at-d-lg-inline-block {
    display: inline-block;
  }

  .at-d-lg-flex {
    display: flex;
  }

  .at-text-lg-center {
    text-align: center;
  }

  .at-text-lg-start {
    text-align: start;
  }

  .at-text-lg-end {
    text-align: end;
  }
}

@media screen and (min-width: 1200px) {
  .at-d-xl-none {
    display: none;
  }

  .at-d-xl-block {
    display: block;
  }

  .at-d-xl-inline-block {
    display: inline-block;
  }

  .at-d-xl-flex {
    display: flex;
  }

  .at-text-xl-center {
    text-align: center;
  }

  .at-text-xl-start {
    text-align: start;
  }

  .at-text-xl-end {
    text-align: end;
  }

  .at-w-xl-100 {
    width: 100%;
  }

  .at-w-xl-auto {
    width: auto;
  }
}
