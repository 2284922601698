.at-fw-400 {
  font-weight: 400;
}

.at-fw-500 {
  font-weight: 500;
}

.at-fw-700 {
  font-weight: 700;
}
