.at-row {
  display: flex;
  flex-wrap: wrap;
}

.at-flex-wrap {
  flex-wrap: wrap;
}

.at-flex-column {
  flex-direction: column;
}

.at-flex-row {
  flex-direction: row;
}

.at-justify-center {
  justify-content: center;
}

.at-justify-between {
  justify-content: space-between;
}

.at-justify-end {
  justify-content: flex-end;
}

.at-align-items-center {
  align-items: center;
}

.at-align-items-start {
  align-items: flex-start;
}

.at-align-items-end {
  align-items: flex-end;
}

.at-align-self-stretch {
  align-self: stretch;
}

.at-col {
  flex: 1 0 0%;
}

.at-col-auto {
  flex: 0 0 auto;
}

.at-col-2 {
  flex: 0 0 auto;
  width: 20%;
}

.at-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.at-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.at-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.at-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.at-col-7 {
  flex: 0 0 auto;
  width: calc(100% - 41.66666667%);
}

.at-col-8 {
  flex: 0 0 auto;
  width: calc(100% - 33.33333333%);
}

.at-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.at-gap-1 {
  gap: 0.25rem; //4px
}

.at-gap-2 {
  gap: 0.5rem; //8px
}

.at-gap-4 {
  gap: 1rem; //16px
}

.at-gap-6 {
  gap: 1.5rem; //24px
}

.at-gap-8 {
  gap: 2rem; //32px
}

.at-gap-9 {
  gap: 2.5rem; //40px
}

.at-order-2 {
  order: 2;
}

.at-order-1 {
  order: 1;
}

.at-order-0 {
  order: 0;
}
